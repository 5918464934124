<template>
  <div>
    <h3 ref="intro">
      Pefect Squares
    </h3>
    <p>
      A number is said to be a <i>perfect square</i>
      if it can be expressed as the product of a whole number by itself, or in other words, as the square of
      a whole number.
      <br><br>
      For example, \(9\) is a perfect square because
      it can be expressed as the product of 3, which is a whole number, by itself, i.e. \(9 = 3^2\).
      <br><br>
      The square root of a perfect square is a whole number. For example, the square root of \(9\) is \(3\).
      <br><br>
      Is contrast \(10\) a not a whole number because it cannot be expressed as the square of a
      whole number.
      Using a calculator, you can check that the square root of \(10 = 3.16227766017\) &mdash; which is not a whole number.
    </p>
    <h3 ref="pg">
      MagicGraph | How to Identify Perfect Squares?
    </h3>
    <p>
      From simple geometry, we know that the area of a square with side \(x\) is \(x^2\).
      We can use this simple fact to check if a number is a perfect square or not.
      <br><br>
      You're given a grid of squares &mdash; as you can see in the MagicGraph.
      Each square has an area of 1.
      <br><br>
      Your goal is to stack these squares adjacent to each other such that the total area equals the given number.
      To stack a square simply tap on it (it will turn red once it is stacked).
      <br><br>
      If you can stack these squares into a larger square whose area equals the given number then that number is a pefect square.
      <br><br> Tap on the shuffle button to generate a new number.
      Tap on the check answer button to check you answer.
    </p>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'SquareRoot',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Square Root';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Parallel Lines', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Transversal', img:'/assets/number-2.svg', action: () => this.goto('trans')},
      {title: 'Pair of Angles', img:'/assets/number-3.svg', action: () => this.goto('angles')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Square Root',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
