import {
  makeResponsive,
  placeTitle,
  placeImage,
  placeInput,
  placeSlider,
  hoverMe,
  placeRec,
  hiddenPt,
  fixedPt,
  clearInputFields,
  dragMe,
  placeArrow,
  placeGravity,
  placeText,
  placeMiddleText,
  placeLine,
  placePoint,
  placeGlider,
  placeRuler,
  placeLeftText,
  placeCircle,
  placeAngle,
  placeDash,
  placeLabel,
  placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
  placeArc,
  placeLogo,
  placeErase,
  placeShuffle,
  placeTest,
  placeSeg,
  setConfig,
  placeStat,
  placeTri,
  shuffle
} from '../../../common/edliy_utils-fractions';
const Boxes = {
    //JXG.Options.line.highlight=false;
    box1: function () {
    //Create the Board
    var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-14, 16, 14, -12],
        keepaspectratio: true, axis:true, ticks:{visible:true},
        grid:true, showCopyright:false, showNavigation:false,
        pan:{enabled:false}, zoom:{enabled:false}});

    brd1.suspendUpdate();
    // Layering
    brd1.options.axis.strokeWidth=0;
    brd1.options.layer['image']=15;
    brd1.options.layer['text']=16;
    brd1.options.layer['line']=16;
    brd1.options.layer['point']=17;
    brd1.options.layer['glider']=17;
    brd1.options.layer['angle']=18;
    brd1.options.board.minimizeReflow = 'none';
    brd1.options.point.showInfobox =false;
    brd1.options.line.highlight=false;
    brd1.options.image.highlight=false;
    brd1.options.text.highlight=false;
    brd1.options.layer['image'] =2;
    brd1.options.layer['point'] =3;
//    brd1.options.text.display='internal';
    //Make the board responsive
    makeResponsive(brd1);
    // Creat titleStyle
    placeLogo(brd1);
    placeTitle(brd1, 'Square Roots & Perfect Square', '(Is the number given below a perfect square?)');
    var i=0;
    var NumList = [4, 9, 16, 25, 49];
    var analytics = placeShuffle(brd1);
    analytics.setLabel('Tap to Shuffle')
    analytics.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    analytics.on('over', function () {this.label.setAttribute({visible:true});});
    analytics.on('out', function () {this.label.setAttribute({visible:false});});
    ////////////////////////////////////////////////////
    var erase = placeTest(brd1);
    erase.setLabel('Tap to Test Your Answer')
    erase.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    erase.on('over', function () {this.label.setAttribute({visible:true});});
    erase.on('out', function () {this.label.setAttribute({visible:false});});
/////////////////////////////////////////////////
    placeMiddleText(brd1, 0, 12, ()=>'Given Number =' + NumList[i]);
    //dragMe(brd1, 5, 5);
    //var stone=placeLine(brd1, [-2,0], [3,5], 10, 'grey');
    //
  //  placeText(brd1, 5, 10, 'Location of CM is shown by crosshair');
    //Titles and subtitle
    //var title = brd1.create('text', [12, 22, '<b> Center of Mass <b>'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/500.)}},);
  //  brd1.create('image', ['/assets/crosshair.svg', [7, 11], [1,1]], {visible: true, fixed:true});
    //var rectImg = brd1.create('image', ['/assets/orangeSquare.svg', [20,16], [2,2]], {visible: true, fixed:true});
    //var massKg = brd1.create('text', [21, 17, '1 kg'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
    //FIRST
    var width=2;
    var num_height=8;
    var num_width=8;
    var rects=[];
    var rects2=[];
    var rectsVisibility=[];
    var rectsVisibility2=[];
    var mass2=0; var mass=0;
    var cmx=0,cmy=0;
    for (let x=0; x<num_width; x++)
    {
        let rectx=[];
        let rectx2=[];
        let vx=[];
        let vx2=[];

        for (let y=0; y< num_height; y++)
        {
            let v = true;
            let v2 = false;
            var rect = brd1.create('image', ['/assets/whiteSquare.svg', [x*width-0.5*num_width*width, y*width-0.5*num_width*width], [2.0,2.0]], {visible: v, opacity:0.5, fixed:true});
            var rect2 = brd1.create('image', ['/assets/purpleSquare.svg', [x*width-0.5*num_width*width, y*width-0.5*num_width*width], [2.0,2.0]], {visible: v2, fixed:true});
            rect.on('up', function(){changeRectColorxy(x,y); });
            rect2.on('up', function(){changeRectColorxy(x,y); });
            rectx.push(rect);
            rectx2.push(rect2);
            vx.push(v);
            vx2.push(v2);
        }
        rects.push(rectx);
        rects2.push(rectx2);
        rectsVisibility.push(vx);
        rectsVisibility2.push(vx2);

    }

    //var array =[7.5, 5.5, 6.5, 4.5];
    //var darray=[8, 6, 7, 5];
  //  var shu =brd1.create('image', ['/assets/shuffle.svg', [16, 16], [2.0, 2.0]], {visible:true, fixed:true, opacity:1});
  //  hoverMe(brd1, shu, 'Tap to Shuffle', 5, -15);
  //  shu.on('down', function(){if(j<3){return j=j+1}else{return j=0}});
  /*  perm[0] =brd1.create('image', ['/assets/orangeSquare.svg', [0*width, 0*width], [2.0,2.0]], {visible:true, fixed:true, opacity:1});
    perm[1] =brd1.create('image', ['/assets/orangeSquare.svg', [1*width, 0*width], [2.0,2.0]], {visible:true, fixed:true, opacity:1});
    perm[2] =brd1.create('image', ['/assets/orangeSquare.svg', [2*width, 0*width], [2.0,2.0]], {visible:true, fixed:true, opacity:1});
    perm[3] =brd1.create('image', ['/assets/orangeSquare.svg', [3*width, 0*width], [2.0,2.0]], {visible:true, fixed:true, opacity:1});
    perm[4] =brd1.create('image', ['/assets/orangeSquare.svg', [3*width, 1*width], [2.0,2.0]], {visible:true, fixed:true, opacity:1});
    perm[5] =brd1.create('image', ['/assets/orangeSquare.svg', [3*width, 2*width], [2.0,2.0]], {visible:true, fixed:true, opacity:1});
    perm[6] =brd1.create('image', ['/assets/orangeSquare.svg', [3*width, 3*width], [2.0,2.0]], {visible:true, fixed:true, opacity:1});
    perm[7] =brd1.create('image', ['/assets/orangeSquare.svg', [4*width, 3*width], [2.0,2.0]], {visible:true, fixed:true, opacity:1});
    perm[8] =brd1.create('image', ['/assets/orangeSquare.svg', [5*width, 3*width], [2.0,2.0]], {visible:true, fixed:true, opacity:1});
    perm[9] =brd1.create('image', ['/assets/orangeSquare.svg', [6*width, 3*width], [2.0,2.0]], {visible:true, fixed:true, opacity:1});
*/
////////////////////////////////////////////////////////////////
//brd1.create('image', ['/assets/triangle.svg', [7, 12], [1.0, 1.0]], {visible:true, fixed:true, opacity:1});
//brd1.create('text', [8.5, 12.5, function(){return '= Location of Fulcrum = (' + (darray[j]).toFixed(2) + ', 0.00'+')'}],{visible: true, fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/800.)}},);

////////////////////////////////////////////////////////////////////////////////////
    function changeRectColorxy(x,y){

        var current_visibility = rectsVisibility[x][y];
        var current_visibility2 = rectsVisibility2[x][y];

        if (current_visibility==true && current_visibility2==false)
        {
            current_visibility=false;
            current_visibility2=true;

        }
        else{
            current_visibility=true;
            current_visibility2=false;
        }

        rects[x][y].setAttribute({visible: current_visibility});
        rects2[x][y].setAttribute({visible: current_visibility2});
        rectsVisibility[x][y]=current_visibility;
        rectsVisibility2[x][y]=current_visibility2;

        var massrect=1;
        var mass=0;
        x=0;
        y=0;
        cmx=0;
        cmy=0;
        for (let x=0;x<num_width;x++)
        {
             for (let y=0;y< num_height;y++)
             {
                 if (rectsVisibility[x][y]!=true)
                 {
                    cmx += massrect*(x*width + (x+1)*width)/2;
                    cmy += massrect*(y*width + (y+1)*width)/2;
                    mass+=1;
                 }
             }
        }
        mass2 = mass;
        console.log('cmx=',cmx,'cmy=',cmy, mass2, mass);
    }
    function clearVisibility(){
      for (let x=0;x<num_width;x++)
      {
           for (let y=0;y< num_height;y++)
           {
             rects[x][y].setAttribute({visible:true});
             rects2[x][y].setAttribute({visible:false});
             rectsVisibility[x][y]=true;
             rectsVisibility2[x][y]=false;
    }
  }
}
  //  var cgxtxt = brd1.create('text', [1.5, 18, 'X_c_m = '],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
//    var cgxtxt2 = brd1.create('text', [4, 18.5, ' &sum; m_i x_i '],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'bottom', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
//    brd1.create('segment',[[3., 18],[5., 18]],{strokeWidth:2, strokeColor:'black', fixed:true});
//    var cgxtxt3 = brd1.create('text', [4, 17.5, '&sum;m_i'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);

///    var cgytxt = brd1.create('text', [1.5, 15, 'Y_c_m = '],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
  //  var cgytxt2 = brd1.create('text', [4, 15.5, ' &sum; m_i y_i'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'bottom', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
//    brd1.create('segment',[[3., 15],[5., 15]],{strokeWidth:2, strokeColor:'black', fixed:true});
//    var cgytxt3 = brd1.create('text', [4, 14.5, '&sum;m_i'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
    placeMiddleText(brd1, 0, 10, function(){return 'Selected Area ='+ mass2});
    var ok1 = brd1.create('image', ['/assets/check.svg', [10, 17.5], [1 , 1]], {visible: false, fixed: true});
    var ok2 = brd1.create('image', ['/assets/check.svg', [10, 14.5], [1 , 1]], {visible: false, fixed: true});
    var wrong1 = brd1.create('image', ['/assets/cross.svg', [10, 17.5], [1 , 1]], {visible: false, fixed: true});
    var wrong2 = brd1.create('image', ['/assets/cross.svg', [10, 14.5], [1 , 1]], {visible: false, fixed: true});
    analytics.on('down', function(){
      clearVisibility();
      if(i<NumList.length-1)
      {
        i+=1;
      }
      else{
        i=0;
      }
    });
  //  var pointCM = brd1.create('image', ['/assets/crosshair.svg', [function(){return (masss*cmx0 + mass2*cmx)/(mass2+masss)- 0.5}  ,function(){return (masss*cmy0 + mass2*cmy)/(mass2+masss)- 0.5}], [1,1]], {visible: true, fixed:true});
///    var cgx = brd1.create('text', [7, 18, function(){return ' = ' + ((masss*cmx0 + mass2*cmx)/(mass2+masss)).toFixed(2)}],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
//    var cgy = brd1.create('text', [7, 15, function(){return ' = ' + ((masss*cmy0 + mass2*cmy)/(mass2+masss)).toFixed(2)}],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
//    brd1.create('text', [8.5, 11.5, function(){return '= Center of Mass =' + '('+ ((masss*cmx0 + mass2*cmx)/(mass2+masss)).toFixed(2) +','+ ((masss*cmy0 + mass2*cmy)/(mass2+masss)).toFixed(2) +')' }],{visible: true, fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/800.)}},);

//    brd1.create('image', ['/assets/check.svg', [12, 15.75], [2 , 2]], {visible: function(){return darray[j]==((masss*cmx0 + mass2*cmx)/(mass2+masss)).toFixed(2)}, fixed: true});
    brd1.unsuspendUpdate();
                      },
              }
export default Boxes;
